/* eslint-disable camelcase */
import {
  computed,
  ref,
} from 'vue';
import Http from '@/services/http';

// Properties
const partnerEnv = ref(null);
const partnerUpgid = ref(null);

// Computed
const computedPartnerEnv = computed(() => ({
  ...(partnerEnv.value ?? {}),
  upgid: partnerUpgid.value,
}));


// Fetch Partner Env
const fetchPartnerEnv = async (partner, setEnv = false) => {
  try {
    const { data } = await Http.post('/api/free/partner', {
      partner,
    });

    const maybePartnerEnv = data?.partner ? data : null;

    if (setEnv) {
      partnerEnv.value = maybePartnerEnv;
    }

    return maybePartnerEnv;
  } catch (_err) {
    if (setEnv) {
      partnerEnv.value = null;
    }

    return null;
  }
};

// Set Partner Env
const setPartnerEnv = (env) => {
  partnerEnv.value = env ?? null;
};

// Load Partner Environment
const loadPartnerEnv = async (flowName, route) => {
  let partnerRedirect = null;

  if (flowName !== 'domo-everywhere' || partnerEnv.value !== null) {
    return {
      partnerEnv: computedPartnerEnv.value,
      partnerRedirect,
    };
  }

  const { upgid } = route.query ?? {};

  partnerUpgid.value = upgid ?? null;

  const { utm_partner, ...queryWithoutPartner } = route.query ?? {};

  const remotePartnerEnv = await fetchPartnerEnv(utm_partner ?? '', false);

  if (remotePartnerEnv?.partner) {
    setPartnerEnv(remotePartnerEnv);
  }

  if (!partnerEnv.value && utm_partner !== undefined) {
    partnerRedirect = {
      name: 'step',
      params: {
        lang: route.params.lang || '',
        flowName,
        stepName: '',
      },
      query: queryWithoutPartner,
      replace: true,
    };
  }

  return {
    partnerEnv: computedPartnerEnv.value,
    partnerRedirect,
  };
};


// Exports
export default function usePartner() {
  return {
    fetchPartnerEnv,
    loadPartnerEnv,
    partnerEnv: computedPartnerEnv.value,
    setPartnerEnv,
  };
}
