export default {
  // CTAS
  ctas: {
    alreadyDomoUser: '¿Ya es usuario de Domo?',
    createAccount: 'Crear una cuenta',
    launchDomoNow: 'Inicie Domo ahora',
    next: 'Siguiente',
    finishAndLaunchDomo: 'Finalizar e iniciar Domo',
    signIn: 'Inicie sesión',
    signUp: 'Crear una cuenta',
  },

  // SHARED
  shared: {
    businessEmail: 'Correo electrónico empresarial',
    businessEmailError: 'Introduzca su correo electronico corporativo',
    changeEmail: 'Cambie la dirección de correo electrónico.',
    checkSpam: 'Compruebe su carpeta de spam.',
    clickToResend: 'click to resend',
    company: 'Empresa',
    companyNameIsRequired: '* El nombre de la empresa es obligatorio',
    confirmationCode: 'Código de confirmación',
    confirmationCodeError: 'That code doesn\'t match the code we sent to {email}',
    contactForAssistance: "Please contact <a class='color-link-blue link' href='mailto:domofree{'@'}domo.com' target='_blank'>domofree{'@'}domo.com</a> for assistance.",
    country: 'País',
    departmentOrTeam: 'Departamento o equipo',
    firstName: 'Nombre',
    firstNameIsRequired: '* Nombre es un campo obligatorio',
    jobTitle: 'Cargo',
    jobTitleIsRequired: '* Cargo es un campo obligatorio',
    lastName: 'Apellidos',
    lastNameIsRequired: '* Apellidos es un campo obligatorio',
    launchingDomo: 'Launching Domo',
    launchingInSeconds: 'Launching Domo in {seconds} seconds',
    notReceivedEmail: '¿No ha recibido nuestro mensaje?',
    notSeeingYourRole: '¿No encuentra su rol?',
    notSeeingYourTeam: '¿No encuentra su equipo?',
    notSeeingYourUseCase: 'Not seeing your use case?',
    password: 'Contraseña',
    passwordLowercase: '1 carácter en minúscula',
    passwordLowercaseRequired: 'Debe utilizar al menos 1 carácter en minúscula',
    passwordMinimum: 'Al menos 8 caracteres',
    passwordMinimumRequired: 'Debe utilizar al menos 8 caracteres',
    passwordNumber: '1 número',
    passwordNumberRequired: 'Debe utilizar al menos 1 número',
    passwordRequirements: "Passwords must contain at least 8 characters, lowercase and uppercase characters, a number, and at least 1 special character ({'!,@,#,$'}).",
    passwordRequirementsLabel: 'Passwords must contain:',
    passwordSpecial: "1 special character (ex: {'@$!#'})",
    passwordSpecialRequired: 'At least 1 special character required',
    passwordUppercase: '1 carácter en mayúscula',
    passwordUppercaseRequired: 'Debe utilizar al menos 1 carácter en mayúscula',
    phone: 'Teléfono',
    pleaseCompleteFields: 'Complete todos los campos obligatorios',
    pleaseEnterValidPhoneNumber: '* Escriba un número de teléfono válido',
    primaryObjectiveRequired: '* Primary objective is required',
    progressLabel: 'Paso {currentStep} de {totalSteps}',
    rateLimitError: 'Too many requests, please try again later',
    resendsRemaining: '({attempts} attempt remaining) | ({attempts} attempts remaining)',
    resendError: 'Unable to resend code, please try again later',
    resendingCode: 'Resending confirmation code',
    resentCode: 'Confirmation code sent',
    role: 'Rol',
    roleIsRequired: '* Rol es un campo obligatorio',
    search: 'Search',
    teamIsRequired: '* Equipo es un campo obligatorio',
    tellUsHowYouWantToUseDomo: 'Tell us how you want to use Domo',
    tellUsWhatYouDo: 'Díganos a qué se dedica.',
    trustedBy: 'Ellos ya confían en Domo',
    useCase: 'Use case',
    walkthroughIsRequired: 'Walk-through response is required',
    wantToScheduleLater: 'Want to schedule later?',
    whatBestDescribesYourRole: '¿Qué opción describe mejor su rol con los datos?',
    whatIsYourJobTitle: '¿Cuál es su cargo?',
    whatTeamAreYouOn: '¿Cuál es su equipo?',
    whatTeamDoYouWorkOn: '¿En qué equipo trabaja?',
    whatWouldYouLikeToAccomplish: 'What would you like to accomplish with Domo?',
    wrongAddress: '¿Está utilizando una dirección de correo electrónico incorrecta?',
  },

  // LEGAL
  legal: {
    acknowledgePrivacyPolicy: 'Al crear una cuenta gratuita, está aceptando la <a class="__legal-link font-semi-bold nowrap" href="https://www.domo.com/company/privacy-policy" target="_blank">Política de privacidad</a> de Domo',
    agreeToTerms: 'I agree to the <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Domo Software as a Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
    autoAgreeToTerms: 'By clicking "next" you agree to the <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Domo Software as a Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
    autoConsent: 'Domo puede enviarle información y ofertas sobre sus productos y servicios para empresas.',
    managePreferences: 'To withdraw consent or manage your subscription preferences, <a class="underline" href="https://www.domo.com/unsubscribe" target="_blank">click here</a>.',
    patents: 'Patents',
    privacyCookiePolicy: 'Privacy and Cookie Policy',
    privacyNotice: 'Política de privacidad',
    requestConsent: 'Allow Domo to send you information and offers about Domo products and services for businesses.',
    termsOfService: 'Terms of Service',
  },

  // PRIVACY LINKS
  privacies: [
    {
      label: 'Cookie Preferences',
      href: 'https://domo.com/company/cookies',
    },
    {
      label: 'Privacy & Cookie Policy',
      href: 'https://domo.com/company/privacy-policy',
    },
    {
      label: 'Patents',
      href: 'https://domo.com/company/patents',
    },
  ],

  // FLOWS
  flows: {

    // SHARED
    shared: {
      steps: {
        email: {
          title: 'Sign up for Domo free.',
          subtitle: null,
        },
        confirm: {
          title: 'Compruebe su bandeja de entrada.',
          subtitle: 'Le hemos enviado un código de seis dígitos a {email}. Introdúzcalo antes de que caduque.',
        },
        profile: {
          title: 'Le damos la bienvenida a Domo.',
          subtitle: 'Estamos configurando su cuenta. Mientras tanto, completemos su perfil de persona en Domo.',
          stepperLabel: 'Complete profile',
          // Should use this? https://vue-i18n.intlify.dev/guide/advanced/component.html#basic-usage
        },
        personal: {
          title: 'Cuéntenos sobre usted.',
          subtitle: 'Ahora que ya se ha registrado, personalicemos su experiencia.',
          stepperLabel: 'Personalize',
        },
        walkthrough: {
          title: '¿Desea un tutorial personalizado?',
          subtitle: null,
          stepperLabel: 'Launch Domo',
        },
      },
    },

    // FREE
    free: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Pruebe Domo de forma gratuita.',
          subtitle: 'Conecte, transforme y visualice sus datos en Domo. Cree su cuenta de Domo hoy mismo.',
          features: [
            '<b>Configurar Domo rápidamente.</b> Inicie Domo y vincule datos en cuestión de minutos.',
            '<b>Conectar y transformar.</b> Conecte y normalice datos con total seguridad.',
            '<b>Visualizar y analizar.</b> Cree y comparta KPI en tiempo real.',
            '<b>Desarrollar y ampliar.</b> Cree aplicaciones personalizadas y comparta datos.',
          ],
        },
      },
    },

    // DOMO-EVERYWHERE
    'domo-everywhere': {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          partnerClientError: 'Referring partner is required',
          partnerRemoteError: "The referring partner provided is invalid. Please contact <a class='underline' href='mailto:partners{'@'}domo.com'>partners{'@'}domo.com</a> for support.",
          pleaseEnterPartner: 'Please enter your referring partner:',
          referralPartner: 'Referral partner',
          title: 'Take your data further.',
          subtitle: 'Extend your current data solution with connectors for all your data and access for all your users.',
          features: [
            '<b>Configurar Domo rápidamente.</b> Inicie Domo y vincule datos en cuestión de minutos.',
            '<b>Conectar y transformar.</b> Conecte y normalice datos con total seguridad.',
            '<b>Visualizar y analizar.</b> Cree y comparta KPI en tiempo real.',
            '<b>Desarrollar y ampliar.</b> Cree aplicaciones personalizadas y comparta datos.',
          ],
        },
      },
    },

    // FREEMIUM
    freemium: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Cree su cuenta gratuita ahora, con acceso indefinido.',
          subtitle: 'Acceda a todas las funciones, con número ilimitado de usuarios, sin límite de tiempo y sin necesidad de tarjetas de crédito.',
          features: [
            '<b>Usuarios ilimitados:</b> No hay límite de cupos, nunca.',
            '<b>Todas las funciones incluidas:</b> Acceda a las más de 50 funciones, como paneles de control BI, integración de datos, herramientas de creación de aplicaciones, gestión de modelos de IA, seguridad y mucho más.',
            '<b>Mano de obra capacitada:</b> Proporcione a todos los usuarios acceso a datos en tiempo real para tomar decisiones rápidas e inteligentes que transformen su empresa.',
            '<b>Precios flexibles:</b> Cuando llegue el momento de pagar, obtenga precios asequibles en función de cuánto use la plataforma.',
          ],
        },
      },
    },

    // SNOWFLAKE
    snowflake: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Try Domo for free.',
          subtitle: 'Simplify data management and get actionable intelligence faster with Snowflake and Domo.',
          features: [
            '<b>Rapid Setup:</b> Power up your Snowflake + Domo environment in minutes.',
            '<b>Connect and Enrich Data:</b> Enrich your Snowflake data foundation via Domo’s robust integration library of 1000+ connectors and on-premises capabilities.',
            '<b>Prepare Data for Analysis:</b> Quickly prepare data with robust ETL capabilities via push down SQL to Snowflake.',
            '<b>Turn Insights into Action:</b> Build action-oriented solutions via BI visualizations and low-code applications accessible to all users at no cost.',
          ],
        },
      },
    },

    // STUDENT
    student: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Domo for Students.',
          studentEmailAddress: 'Student email address',
          eligibleLabel: 'I am actively enrolled as a student at an accredited U.S. college or university and am at least 18 years old.',
          eligibleError: 'Please verify your eligibility',
          studentEmailError: "The student email address provided is either invalid or not associated with a known university. Please contact <a class='underline' href='mailto:highereducation{'@'}domo.com'>highereducation{'@'}domo.com</a> for support.",
          subtitle: 'Try Domo for free for one year and learn critical data skills. Domo brings together data, systems, and people for a digitally connected business. Learning how businesses use data in a connected world will give you the skills you need to stand out and land the perfect job.',
          featuresLabel: 'With Domo\'s free trial for actively enrolled students, you will have access to exciting opportunities, just for you.',
          features: [
            '<b>Get Domo Certified:</b> Gain experience using the Domo product to help you complete our professional-level certification.',
            '<b>Learn the skills employers are looking for:</b> Domo comes packed with hundreds of training courses and videos to teach you all the skills you need to land the perfect job: data analysis, data storytelling, visualization strategies, SQL, ETL, "big data", app development, and more.',
          ],
        },
        profile: {
          subtitle: 'Please complete the below so that we are able to verify your eligibility for a Domo student account.',
          country: 'Country of residence',
          agreeToTerms: 'I agree to the <a class="underline" href="https://web-assets.domo.com/blog/wp-content/uploads/2024/09/Domo-Student-Instance_Sept2024v2.pdf" target="_blank">Domo for Students Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
        },
        enrollment: {
          title: 'Tell us about your enrollment at {university}',
          graduationLabel: 'When is your expected graduation date?',
          month: 'Month',
          stepperLabel: 'Enrollment',
          studyProgramLabel: 'What is your program of study?',
          studyProgramPlaceholder: 'Program of study',
          year: 'Year',
        },
      },
    },

    // DEVELOPER
    developer: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Get your developer trial.',
          subtitle: 'Start your free trial to start developing on Domo today.',
          features: [
            'Full-featured, dev-ready environment',
            'Connector IDE and data API ready',
            'Pre-loaded with sample data & cards',
            'Extensive documentation and guides',
            'SDK for building custom apps',
          ],
          finePrint: 'Free for customers, partners, and prospective partners.<br/><br/>By requesting a developer trial you agree to <a class="underline" target="_blank" href="https://web-assets.domo.com/blog/wp-content/uploads/2021/09/Domo-Developer-Trial-Agreement-2021.pdf">Domo\'s terms of service.</a>',
        },
      },
    },

    // IBM
    ibm: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Pruebe Domo de forma gratuita.',
          subtitle: 'Conecte, transforme y visualice sus datos en Domo. Cree su cuenta de Domo hoy mismo.',
          features: [
            '<b>Configurar Domo rápidamente.</b> Inicie Domo y vincule datos en cuestión de minutos.',
            '<b>Conectar y transformar.</b> Conecte y normalice datos con total seguridad.',
            '<b>Visualizar y analizar.</b> Cree y comparta KPI en tiempo real.',
            '<b>Desarrollar y ampliar.</b> Cree aplicaciones personalizadas y comparta datos.',
          ],
        },
        useCase: {
          title: 'Tell us about your use case.',
          subtitle: 'Domo can do a lot—help us understand what you hope to accomplish.',
        },
      },
    },
  },

  // OPTIONS
  companySizes: [
    {
      value: '5000+',
      label: '5000+',
    },
    {
      value: '1000-4999',
      label: '1000-4999',
    },
    {
      value: '500-999',
      label: '500-999',
    },
    {
      value: '100-499',
      label: '100-499',
    },
    {
      value: '50-99',
      label: '50-99',
    },
    {
      value: '1-49',
      label: '1-49',
    },
  ],

  departments: [
    {
      value: 'BI/Analytics',
      label: 'BI/Análisis',
    },
    {
      value: 'IT/Engineering',
      label: 'TI/Ingeniería',
    },
    {
      value: 'Finance',
      label: 'Finanzas',
    },
    {
      value: 'Human Resources',
      label: 'Recursos Humanos',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Operations',
      label: 'Operaciones',
    },
    {
      value: 'Sales',
      label: 'Ventas',
    },
    {
      value: 'Support/Services',
      label: 'Asistencia/Servicios',
    },
  ],

  months: [
    {
      value: 'January',
      label: 'January',
    },
    {
      value: 'February',
      label: 'February',
    },
    {
      value: 'March',
      label: 'March',
    },
    {
      value: 'April',
      label: 'April',
    },
    {
      value: 'May',
      label: 'May',
    },
    {
      value: 'June',
      label: 'June',
    },
    {
      value: 'July',
      label: 'July',
    },
    {
      value: 'August',
      label: 'August',
    },
    {
      value: 'September',
      label: 'September',
    },
    {
      value: 'October',
      label: 'October',
    },
    {
      value: 'November',
      label: 'November',
    },
    {
      value: 'December',
      label: 'December',
    },
  ],

  studentPrograms: [
    {
      value: 'Business Administration',
      label: 'Business Administration',
    },
    {
      value: 'Business Management',
      label: 'Business Management',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Finance',
      label: 'Finance',
    },
    {
      value: 'Accounting',
      label: 'Accounting',
    },
    {
      value: 'Economics',
      label: 'Economics',
    },
    {
      value: 'Human Resource Management',
      label: 'Human Resource Management',
    },
    {
      value: 'Education Administration',
      label: 'Education Administration',
    },
    {
      value: 'Information Systems and Technologies',
      label: 'Information Systems and Technologies',
    },
    {
      value: 'Healthcare Management',
      label: 'Healthcare Management',
    },
    {
      value: 'EcPublic Policyonomics',
      label: 'Public Policy',
    },
    {
      value: 'Environmental Science',
      label: 'Environmental Science',
    },
    {
      value: 'Social Studies & Psychology',
      label: 'Social Studies & Psychology',
    },
    {
      value: 'Other',
      label: 'Other',
    },
  ],

  roles: [
    {
      value: 'Data Creator / Analyst',
      label: '<b>Creador de datos/Analista</b><br><span class="color-mute font-14">Crear y proporcionar informes para terceros como analista o profesional de datos</span>',
      icon: 'graph-report',
    },
    {
      value: 'Data Consumer',
      label: '<b>Consumidor de datos</b><br><span class="color-mute font-14">Consumir principalmente informes creados por otros</span>',
      icon: 'graph-computer',
    },
    {
      value: 'Data Engineer',
      label: '<b>Ingeniero de datos</b><br><span class="color-mute font-14">Integrar, crear y gestionar recursos de datos en toda la organización</span>',
      icon: 'cogs',
    },
  ],

  useCases: [
    {
      value: 'Create real-time, interactive dashboards',
      label: 'Create real-time, interactive dashboards',
      icon: 'graph-computer',
    },
    {
      value: 'Make data more accessible for teams and execs',
      label: 'Make data more accessible for teams and execs',
      icon: 'users-circle',
    },
    {
      value: 'Integrate and analyze data from various sources',
      label: 'Integrate and analyze data from various sources',
      icon: 'graph-tree',
    },
    {
      value: 'Centralize all data and reporting',
      label: 'Centralize all data and reporting',
      icon: 'graph-line',
    },
    {
      value: 'Automate reporting',
      label: 'Automate reporting',
      icon: 'webpage',
    },
    {
      value: 'Provide dashboards to customers and partners',
      label: 'Provide dashboards to customers and partners',
      icon: 'hand-click',
    },
  ],

  poc: [
    {
      value: true,
      label: 'Sí, quiero un tutorial gratuito y personalizado con un experto en productos de Domo.',
    },
    {
      value: false,
      label: 'No, me gustaría solucionarlo por mi cuenta.',
    },
  ],

  countries: [
    {
      value: 'US',
      label: 'United States of America',
    },
    {
      value: 'GB',
      label: 'United Kingdom',
    },
    {
      value: 'CA',
      label: 'Canada',
    },
    {
      value: 'DE',
      label: 'Germany',
    },
    {
      value: 'JP',
      label: 'Japan',
    },
    {
      value: 'AF',
      label: 'Afghanistan',
    },
    {
      value: 'AX',
      label: 'Aland Islands',
    },
    {
      value: 'AL',
      label: 'Albania',
    },
    {
      value: 'DZ',
      label: 'Algeria',
    },
    {
      value: 'AS',
      label: 'American Samoa',
    },
    {
      value: 'AD',
      label: 'Andorra',
    },
    {
      value: 'AO',
      label: 'Angola',
    },
    {
      value: 'AI',
      label: 'Anguilla',
    },
    {
      value: 'AQ',
      label: 'Antarctica',
    },
    {
      value: 'AG',
      label: 'Antigua and Barbuda',
    },
    {
      value: 'AR',
      label: 'Argentina',
    },
    {
      value: 'AM',
      label: 'Armenia',
    },
    {
      value: 'AW',
      label: 'Aruba',
    },
    {
      value: 'AU',
      label: 'Australia',
    },
    {
      value: 'AT',
      label: 'Austria',
    },
    {
      value: 'AZ',
      label: 'Azerbaijan',
    },
    {
      value: 'BS',
      label: 'Bahamas',
    },
    {
      value: 'BH',
      label: 'Bahrain',
    },
    {
      value: 'BD',
      label: 'Bangladesh',
    },
    {
      value: 'BB',
      label: 'Barbados',
    },
    {
      value: 'BY',
      label: 'Belarus',
    },
    {
      value: 'BE',
      label: 'Belgium',
    },
    {
      value: 'BZ',
      label: 'Belize',
    },
    {
      value: 'BJ',
      label: 'Benin',
    },
    {
      value: 'BM',
      label: 'Bermuda',
    },
    {
      value: 'BT',
      label: 'Bhutan',
    },
    {
      value: 'BO',
      label: 'Bolivia',
    },
    {
      value: 'BA',
      label: 'Bosnia and Herzegovina',
    },
    {
      value: 'BW',
      label: 'Botswana',
    },
    {
      value: 'BV',
      label: 'Bouvet Island',
    },
    {
      value: 'BR',
      label: 'Brazil',
    },
    {
      value: 'IO',
      label: 'British Indian Ocean Territory',
    },
    {
      value: 'BN',
      label: 'Brunei Darussalam',
    },
    {
      value: 'BG',
      label: 'Bulgaria',
    },
    {
      value: 'BF',
      label: 'Burkina Faso',
    },
    {
      value: 'BI',
      label: 'Burundi',
    },
    {
      value: 'KH',
      label: 'Cambodia',
    },
    {
      value: 'CM',
      label: 'Cameroon',
    },
    {
      value: 'CV',
      label: 'Cape Verde',
    },
    {
      value: 'KY',
      label: 'Cayman Islands',
    },
    {
      value: 'CF',
      label: 'Central African Republic',
    },
    {
      value: 'TD',
      label: 'Chad',
    },
    {
      value: 'CL',
      label: 'Chile',
    },
    {
      value: 'CN',
      label: 'China',
    },
    {
      value: 'CX',
      label: 'Christmas Island',
    },
    {
      value: 'CC',
      label: 'Cocos (Keeling) Islands',
    },
    {
      value: 'CO',
      label: 'Colombia',
    },
    {
      value: 'KM',
      label: 'Comoros',
    },
    {
      value: 'CG',
      label: 'Congo',
    },
    {
      value: 'CD',
      label: 'Congo, Democratic Republic',
    },
    {
      value: 'CK',
      label: 'Cook Islands',
    },
    {
      value: 'CR',
      label: 'Costa Rica',
    },
    {
      value: 'CI',
      label: 'Cote D\'ivoire',
    },
    {
      value: 'HR',
      label: 'Croatia',
    },
    {
      value: 'CU',
      label: 'Cuba',
    },
    {
      value: 'CY',
      label: 'Cyprus',
    },
    {
      value: 'CZ',
      label: 'Czech Republic',
    },
    {
      value: 'DK',
      label: 'Denmark',
    },
    {
      value: 'DJ',
      label: 'Djibouti',
    },
    {
      value: 'DM',
      label: 'Dominica',
    },
    {
      value: 'DO',
      label: 'Dominican Republic',
    },
    {
      value: 'EC',
      label: 'Ecuador',
    },
    {
      value: 'EG',
      label: 'Egypt',
    },
    {
      value: 'SV',
      label: 'El Salvador',
    },
    {
      value: 'GQ',
      label: 'Equatorial Guinea',
    },
    {
      value: 'ER',
      label: 'Eritrea',
    },
    {
      value: 'EE',
      label: 'Estonia',
    },
    {
      value: 'ET',
      label: 'Ethiopia',
    },
    {
      value: 'FK',
      label: 'Falkland Islands (Malvinas)',
    },
    {
      value: 'FO',
      label: 'Faroe Islands',
    },
    {
      value: 'FJ',
      label: 'Fiji',
    },
    {
      value: 'FI',
      label: 'Finland',
    },
    {
      value: 'FR',
      label: 'France',
    },
    {
      value: 'GF',
      label: 'French Guiana',
    },
    {
      value: 'PF',
      label: 'French Polynesia',
    },
    {
      value: 'TF',
      label: 'French Southern Territories',
    },
    {
      value: 'GA',
      label: 'Gabon',
    },
    {
      value: 'GM',
      label: 'Gambia',
    },
    {
      value: 'GE',
      label: 'Georgia',
    },
    {
      value: 'GH',
      label: 'Ghana',
    },
    {
      value: 'GI',
      label: 'Gibraltar',
    },
    {
      value: 'GR',
      label: 'Greece',
    },
    {
      value: 'GL',
      label: 'Greenland',
    },
    {
      value: 'GD',
      label: 'Grenada',
    },
    {
      value: 'GP',
      label: 'Guadeloupe',
    },
    {
      value: 'GU',
      label: 'Guam',
    },
    {
      value: 'GT',
      label: 'Guatemala',
    },
    {
      value: 'GG',
      label: 'Guernsey',
    },
    {
      value: 'GN',
      label: 'Guinea',
    },
    {
      value: 'GW',
      label: 'Guinea-bissau',
    },
    {
      value: 'GY',
      label: 'Guyana',
    },
    {
      value: 'HT',
      label: 'Haiti',
    },
    {
      value: 'HM',
      label: 'Heard Island and Mcdonald Islands',
    },
    {
      value: 'VA',
      label: 'Holy See (Vatican City State)',
    },
    {
      value: 'HN',
      label: 'Honduras',
    },
    {
      value: 'HK',
      label: 'Hong Kong',
    },
    {
      value: 'HU',
      label: 'Hungary',
    },
    {
      value: 'IS',
      label: 'Iceland',
    },
    {
      value: 'IN',
      label: 'India',
    },
    {
      value: 'ID',
      label: 'Indonesia',
    },
    {
      value: 'IR',
      label: 'Iran, Islamic Republic',
    },
    {
      value: 'IQ',
      label: 'Iraq',
    },
    {
      value: 'IE',
      label: 'Ireland',
    },
    {
      value: 'IM',
      label: 'Isle of Man',
    },
    {
      value: 'IL',
      label: 'Israel',
    },
    {
      value: 'IT',
      label: 'Italy',
    },
    {
      value: 'JM',
      label: 'Jamaica',
    },
    {
      value: 'JE',
      label: 'Jersey',
    },
    {
      value: 'JO',
      label: 'Jordan',
    },
    {
      value: 'KZ',
      label: 'Kazakhstan',
    },
    {
      value: 'KE',
      label: 'Kenya',
    },
    {
      value: 'KI',
      label: 'Kiribati',
    },
    {
      value: 'KP',
      label: 'Korea, Democratic People\'s Republic',
    },
    {
      value: 'KR',
      label: 'Korea, Republic of',
    },
    {
      value: 'KW',
      label: 'Kuwait',
    },
    {
      value: 'KG',
      label: 'Kyrgyzstan',
    },
    {
      value: 'LA',
      label: 'Lao People\'s Democratic Republic',
    },
    {
      value: 'LV',
      label: 'Latvia',
    },
    {
      value: 'LB',
      label: 'Lebanon',
    },
    {
      value: 'LS',
      label: 'Lesotho',
    },
    {
      value: 'LR',
      label: 'Liberia',
    },
    {
      value: 'LY',
      label: 'Libyan Arab Jamahiriya',
    },
    {
      value: 'LI',
      label: 'Liechtenstein',
    },
    {
      value: 'LT',
      label: 'Lithuania',
    },
    {
      value: 'LU',
      label: 'Luxembourg',
    },
    {
      value: 'MO',
      label: 'Macao',
    },
    {
      value: 'MK',
      label: 'Macedonia',
    },
    {
      value: 'MG',
      label: 'Madagascar',
    },
    {
      value: 'MW',
      label: 'Malawi',
    },
    {
      value: 'MY',
      label: 'Malaysia',
    },
    {
      value: 'MV',
      label: 'Maldives',
    },
    {
      value: 'ML',
      label: 'Mali',
    },
    {
      value: 'MT',
      label: 'Malta',
    },
    {
      value: 'MH',
      label: 'Marshall Islands',
    },
    {
      value: 'MQ',
      label: 'Martinique',
    },
    {
      value: 'MR',
      label: 'Mauritania',
    },
    {
      value: 'MU',
      label: 'Mauritius',
    },
    {
      value: 'YT',
      label: 'Mayotte',
    },
    {
      value: 'MX',
      label: 'Mexico',
    },
    {
      value: 'FM',
      label: 'Micronesia, Federated States',
    },
    {
      value: 'MD',
      label: 'Moldova, Republic',
    },
    {
      value: 'MC',
      label: 'Monaco',
    },
    {
      value: 'MN',
      label: 'Mongolia',
    },
    {
      value: 'ME',
      label: 'Montenegro',
    },
    {
      value: 'MS',
      label: 'Montserrat',
    },
    {
      value: 'MA',
      label: 'Morocco',
    },
    {
      value: 'MZ',
      label: 'Mozambique',
    },
    {
      value: 'MM',
      label: 'Myanmar',
    },
    {
      value: 'NA',
      label: 'Namibia',
    },
    {
      value: 'NR',
      label: 'Nauru',
    },
    {
      value: 'NP',
      label: 'Nepal',
    },
    {
      value: 'NL',
      label: 'Netherlands',
    },
    {
      value: 'AN',
      label: 'Netherlands Antilles',
    },
    {
      value: 'NC',
      label: 'New Caledonia',
    },
    {
      value: 'NZ',
      label: 'New Zealand',
    },
    {
      value: 'NI',
      label: 'Nicaragua',
    },
    {
      value: 'NE',
      label: 'Niger',
    },
    {
      value: 'NG',
      label: 'Nigeria',
    },
    {
      value: 'NU',
      label: 'Niue',
    },
    {
      value: 'NF',
      label: 'Norfolk Island',
    },
    {
      value: 'MP',
      label: 'Northern Mariana Islands',
    },
    {
      value: 'NO',
      label: 'Norway',
    },
    {
      value: 'OM',
      label: 'Oman',
    },
    {
      value: 'PK',
      label: 'Pakistan',
    },
    {
      value: 'PW',
      label: 'Palau',
    },
    {
      value: 'PS',
      label: 'Palestinian Territory, Occupied',
    },
    {
      value: 'PA',
      label: 'Panama',
    },
    {
      value: 'PG',
      label: 'Papua New Guinea',
    },
    {
      value: 'PY',
      label: 'Paraguay',
    },
    {
      value: 'PE',
      label: 'Peru',
    },
    {
      value: 'PH',
      label: 'Philippines',
    },
    {
      value: 'PN',
      label: 'Pitcairn',
    },
    {
      value: 'PL',
      label: 'Poland',
    },
    {
      value: 'PT',
      label: 'Portugal',
    },
    {
      value: 'PR',
      label: 'Puerto Rico',
    },
    {
      value: 'QA',
      label: 'Qatar',
    },
    {
      value: 'RE',
      label: 'Reunion',
    },
    {
      value: 'RO',
      label: 'Romania',
    },
    {
      value: 'RU',
      label: 'Russian Federation',
    },
    {
      value: 'RW',
      label: 'Rwanda',
    },
    {
      value: 'SH',
      label: 'Saint Helena',
    },
    {
      value: 'KN',
      label: 'Saint Kitts and Nevis',
    },
    {
      value: 'LC',
      label: 'Saint Lucia',
    },
    {
      value: 'PM',
      label: 'Saint Pierre and Miquelon',
    },
    {
      value: 'VC',
      label: 'Saint Vincent and The Grenadines',
    },
    {
      value: 'WS',
      label: 'Samoa',
    },
    {
      value: 'SM',
      label: 'San Marino',
    },
    {
      value: 'ST',
      label: 'Sao Tome and Principe',
    },
    {
      value: 'SA',
      label: 'Saudi Arabia',
    },
    {
      value: 'SN',
      label: 'Senegal',
    },
    {
      value: 'RS',
      label: 'Serbia',
    },
    {
      value: 'SC',
      label: 'Seychelles',
    },
    {
      value: 'SL',
      label: 'Sierra Leone',
    },
    {
      value: 'SG',
      label: 'Singapore',
    },
    {
      value: 'SK',
      label: 'Slovakia',
    },
    {
      value: 'SI',
      label: 'Slovenia',
    },
    {
      value: 'SB',
      label: 'Solomon Islands',
    },
    {
      value: 'SO',
      label: 'Somalia',
    },
    {
      value: 'ZA',
      label: 'South Africa',
    },
    {
      value: 'GS',
      label: 'South Georgia',
    },
    {
      value: 'ES',
      label: 'Spain',
    },
    {
      value: 'LK',
      label: 'Sri Lanka',
    },
    {
      value: 'SD',
      label: 'Sudan',
    },
    {
      value: 'SR',
      label: 'Suriname',
    },
    {
      value: 'SJ',
      label: 'Svalbard and Jan Mayen',
    },
    {
      value: 'SZ',
      label: 'Swaziland',
    },
    {
      value: 'SE',
      label: 'Sweden',
    },
    {
      value: 'CH',
      label: 'Switzerland',
    },
    {
      value: 'SY',
      label: 'Syrian Arab Republic',
    },
    {
      value: 'TW',
      label: 'Taiwan, Province of China',
    },
    {
      value: 'TJ',
      label: 'Tajikistan',
    },
    {
      value: 'TZ',
      label: 'Tanzania, United Republic',
    },
    {
      value: 'TH',
      label: 'Thailand',
    },
    {
      value: 'TL',
      label: 'Timor-leste',
    },
    {
      value: 'TG',
      label: 'Togo',
    },
    {
      value: 'TK',
      label: 'Tokelau',
    },
    {
      value: 'TO',
      label: 'Tonga',
    },
    {
      value: 'TT',
      label: 'Trinidad and Tobago',
    },
    {
      value: 'TN',
      label: 'Tunisia',
    },
    {
      value: 'TR',
      label: 'Turkey',
    },
    {
      value: 'TM',
      label: 'Turkmenistan',
    },
    {
      value: 'TC',
      label: 'Turks and Caicos Islands',
    },
    {
      value: 'TV',
      label: 'Tuvalu',
    },
    {
      value: 'UG',
      label: 'Uganda',
    },
    {
      value: 'UA',
      label: 'Ukraine',
    },
    {
      value: 'AE',
      label: 'United Arab Emirates',
    },
    {
      value: 'UM',
      label: 'United States Minor Islands',
    },
    {
      value: 'UY',
      label: 'Uruguay',
    },
    {
      value: 'UZ',
      label: 'Uzbekistan',
    },
    {
      value: 'VU',
      label: 'Vanuatu',
    },
    {
      value: 'VE',
      label: 'Venezuela',
    },
    {
      value: 'VN',
      label: 'Vietnam',
    },
    {
      value: 'VG',
      label: 'Virgin Islands, British',
    },
    {
      value: 'VI',
      label: 'Virgin Islands, U.S.',
    },
    {
      value: 'WF',
      label: 'Wallis and Futuna',
    },
    {
      value: 'EH',
      label: 'Western Sahara',
    },
    {
      value: 'YE',
      label: 'Yemen',
    },
    {
      value: 'ZM',
      label: 'Zambia',
    },
    {
      value: 'ZW',
      label: 'Zimbabwe',
    },
  ],
};
