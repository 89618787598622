export function useHead() {
  function setMetaTag(name, content) {
    const metaTag = document.createElement('meta');

    metaTag.name = name;
    metaTag.content = content;

    document.head.appendChild(metaTag);
  }

  function setTitle(title, appName = 'Domo') {
    document.title = [title, appName].filter((i) => !!i).join(' | ');
  }

  function setFlowHead(flowConfig) {
    const { head } = flowConfig;

    if (!head) return;

    const { title, meta } = head;

    if (title) setTitle(title);

    if (meta) {
      Object.entries(meta).forEach(([name, content]) => setMetaTag(name, content));
    }
  }

  return {
    setFlowHead,
  };
}
